import { useRouter } from 'next/router';
import { useEffect } from 'react';
import logger from '@/utils/logger';
import Title from '@/components/bricks/Title/Title';
import { LoadingOverlay } from '@mantine/core';
import { gql } from '@/__generated__';
import { useQuery } from 'urql';

const healthDataCountQuery = gql(`
  query HealthDataCount {
    suiteExecutionsSummaryAggregate(
      distinctOn: [suiteId, url],
      where: {
        _or: [
          {url:{_isNull: false}},
        ]
      }
    ) {
      aggregate {
        count
      }
    }
  }
`);

function Home() {
  const router = useRouter();
  const [{ data: healthDataCount, fetching: fetchingHealthDataCount }] =
    useQuery({
      query: healthDataCountQuery,
    });

  useEffect(() => {
    if (fetchingHealthDataCount) {
      return;
    }
    router
      .replace(
        healthDataCount?.suiteExecutionsSummaryAggregate?.aggregate?.count
          ? '/health'
          : '/flows/tests',
      )
      .catch((e) => logger.error(e));
  }, [router, healthDataCount, fetchingHealthDataCount]);
  return (
    <>
      <Title title="Mockingjay" />
      <div
        style={{
          position: 'relative',
          width: '100%',
          height: 'calc(100vh - var(--mj-header-height))',
        }}
      >
        <LoadingOverlay visible />
      </div>
    </>
  );
}

export default Home;
