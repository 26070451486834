import Head from 'next/head';
import { PropsWithChildren, createContext, useContext, useMemo } from 'react';

type TitleProps = {
  title: string | string[];
};

export const TitleContext = createContext<string[]>([]);

export function WithTitle({ children, title }: PropsWithChildren<TitleProps>) {
  const parentTitles = useContext(TitleContext);
  const currentTitle = Array.isArray(title) ? title : [title];
  return (
    <TitleContext.Provider value={currentTitle.concat(parentTitles)}>
      <Title />
      {children}
    </TitleContext.Provider>
  );
}

function Title({ title }: Partial<TitleProps>) {
  const parentTitles = useContext(TitleContext);

  const pageTitle = useMemo(() => {
    const currentTitle = Array.isArray(title) ? title : [title];
    return currentTitle
      .concat(parentTitles)
      .filter((t) => !!t)
      .join(' | ');
  }, [parentTitles, title]);

  return (
    <Head>
      <title>{`${pageTitle} | Mockingjay`}</title>
    </Head>
  );
}

export default Title;
